import React from "react"
import Logo from './logo';

const headerStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: 20,
  backgroundColor: '#faf9f9',
  boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.5)',
  position: 'relative',
  zIndex: 100000,
};

const navStyle = {
  marginTop: 20,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 50,
  borderTop: '1px solid #ddd',
};

const navItemStyle = {
  color: '#AC4456',
  textDecoration: 'none',
  fontFamily: 'Rokkitt',
  textTransform: 'uppercase',
  fontSize: 14,
  width: 128,
  textAlign: 'center',
};


const NavLink = ({children, to}) => (
  <a style={navItemStyle} href={to}>{children}</a>
);


const Header = () => (
  <header style={headerStyle}>
    <Logo />
    <nav style={navStyle}>
      <NavLink to="#palvelut">Palvelut</NavLink>
      <NavLink to="#hinnasto">Hinnasto</NavLink>
      <NavLink to="#yhteystiedot">Yhteystiedot</NavLink>
    </nav>
  </header>
)

export default Header
